:root {
  --es-primary-color: #29023F;
  --es-secondary-color: #EF5150;
  --es-white-color: #F9F9F9;
  --es-dark-color: #292b2c;
  --es-hover-color: #36008D;
  --es-transparency: 0.666;
  --es-primary-font-fg: Inter;
  --es-light-font-fg: InterLight;
  --es-bold-font-fg: InterBold;
}

:focus {
  outline: var(--es-primary-color) auto 1px;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'Inter', 'Roboto', 'Fira Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(255,255,255, var(--es-transparency));
}

body::before {
  content: "";
  display: block;
  position: absolute;
  background: #282c34;
  padding-bottom: 1%;
  background-image: url("./images/network.png");
  background-size: cover;
  background-position: center bottom;
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.25;
}

.White-Text {
  font-color: #ffffff;
  color: white;
}

.Black-Text {
  font-color: #000000;
  color: #000000;
}

h3 {
  font-weight: normal!important;
  font-size: 1.2em!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter/Inter-Regular.ttf") format("truetype");;
}

@font-face {
  font-family: "InterLight";
  src: local("InterLight"), url("./fonts/Inter/Inter-Light.ttf") format("truetype");;
}

@font-face {
  font-family: "InterBold";
  src: local("InterBold"), url("./fonts/Inter/Inter-Bold.ttf") format("truetype");;
}

@font-face{
  font-family: "Roboto";
  src: local("Roboto"), url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face{
  font-family: "RobotoBold";
  src: local("RobotoBold"), url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}
