.App {
    height: 80%;
}

.App {
  text-align: center;
}

.StatusPane {
  font-family: var(--es-light-font-fg), serif;
  background-color: var(--es-white-color);
  color: var(--es-primary-color);
  user-select: none;
  padding: 6px;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}

.StatusPane > #balance {
  font-weight: bold;
}

.HelpCircle {
  width: 2em;
  height: 2em;
  background-color: var(--es-white-color);
  color: var(--es-primary-color);
  border-radius: 50%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border: none;
  text-align: center;
  padding: 2px;
  margin: 2px 10px 0 0;
}

.HelpCircle:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.PrivacyLink {
  position: absolute;
  bottom: 0;
  right: 1em;
  border: none;
  background-color: inherit;
  text-decoration: underline;
}

.contact {
  position: absolute;
  bottom: 0;
  right: 10em;
  border: none;
}

.custom-dropdown-menu {
  background-color: transparent;
}

.Contact {
  position: absolute;
  bottom: 0.1rem;
  left: 0.5rem;
}

.BottomLeft {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 400px;
  height: auto;
}
.BottomLeft p {
  position: absolute;
  bottom: 10px;
  left: 100px;
  margin: 0;
}

.BottomLeft .first-child {
  bottom: 40px;
}

.Onboarding {
  width: 100%;
  position: absolute;
  top: 45%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.Onboarding > .row {
  margin: 0;
}

.loading-spinner {
  margin-bottom: 20px;
}

.SwapContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 800px;
  min-height: 755px;
  max-width: 45vw;
  max-height: 755px;
  background-color: rgba(255,255,255, var(--es-transparency));
  padding-top: 1vh;
  padding-bottom: 1vh;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -60%);
}

.SwapContainer tbody tr {
  cursor: pointer;
}

.HistoryContainer {
  height: 75vh;
  background-color: rgba(255,255,255, var(--es-transparency));
  padding-top: 1vh;
  padding-bottom: 1vh;
  box-shadow: rgba(0,0,0,.45) 0 15px 15px;
  border-radius: 5px;
}

.ListViewWrapper {
  height: 70vh;
  overflow-y: scroll;
}

.ListViewWrapper table {
  border-collapse: separate;
  border-spacing: 0;
}

.ListViewWrapper thead th {
  background-color: white;
  position: sticky;
  top: 1px;
  z-index: 1;
}

.swap-form {
  width: 100%;
  margin-top: 3%;
  min-height: 550px;
  height: 60vh;
}

.chains-list {
  max-height: 150px;
  margin-bottom: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.metaMaskNotice {
  min-width: 220px;
}

.feedback {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  animation: fadeOut 1.5s ease-in-out;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.conn-symbol {
  float: right;
  margin-left: 5px;
}

@media screen and ( max-height: 850px )  {
  /* Set margin-top to 0 for displays with small height. */
  .s-mt-0 {
    margin-top: 0;
  }
}

.back-button {
  float: left;
  width: 45px;
  height: 30px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  border-radius: 5px;
  padding-top: 2px;
  padding-right: 5px;
  cursor: pointer;
}

.close-button {
  float: right;
  width: 45px;
  height: 30px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  border-radius: 5px;
  padding-top: 2px;
  cursor: pointer;
}

.copy-button {
  display: inline-block;
  vertical-align: top;
  padding: 5px;
  width: 45px;
  height: 30px;
  color: rgb(128,128,128);
  cursor: pointer;
}

.copy-button:active {
  color: var(--es-hover-color);
}

.swap-form .row {
  align-items: stretch;
  display: flex;
  justify-content: space-between;
}

.swap-form .padding {
  margin-top: 5%;
}

.swap-form .inner-row {
  justify-content: center;
  align-items: center;
  display: flex;
}

.swap-form .rate {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 4px;
}

.swap-form .dropdown-container {
  position: relative;
}

.swap-form button, .swap-form .dropdown-item {
  width: 280px!important;
}

.swap-form button, .swap-form .dropdown-button {
  width: 175px!important;
  height: 40px!important;
}

.swap-form .tx-fee {
  width: 200px!important;
  height: 40px!important;
  background-color: rgb(164, 173, 172);
  border-radius: 3px;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.swap-form .tx-fee p {
  margin: 0;
  color: #eeeeee;
}

.swap-form .dropdown-item:active {
  width: 280px!important;
  background-color: var(--es-hover-color);
}

.swap-form .form-control {
  width: 250px!important;
  height: 40px!important;
}

.form-control:focus {
  box-shadow: none;
}

.swap-form .amount-selection {
  width: 440px!important;
  height: 40px!important;
  border-color: #00C9B8;
  border-width: 3px;
  border-style: solid;
  border-radius: 3px;
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: space-evenly;
}

.swap-form .help-button {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border-color: #A4ADACFF;
  border-width: 3px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px
}

.swap-form .help-button span {
  font-size: 20px;
  color: #A4ADACFF;
}

.swap-form .amount-selection p {
  margin: 0;
  color: black;
}

.swap-form .amount-selection .arrow-icon {
  color: black;
}

.swap-form .clear-button {
  width: 90px!important;
  font-size: 14px!important;
}

.asset-container {
  display: inline-block;
  width: fit-content;
  padding: 2px 8px 1px 4px;
  border-radius: 5px;
}

.asset-logo {
  width: 25px;
  height: 25px;
  margin: 0 2px 2px 0;
}

.asset-logo-append .asset-container {
  padding-top: 4px;
}

.flex-fill {
  flex:1;
}

.swap-form .rateContainter {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 430px;
  height: 60px;
}

.swap-summary {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.hub-info {
  display: inline;
  text-decoration: underline;
}

.receiver-address-form .custom-control-input[type='checkbox']:checked + .custom-control-label::before,
.receiver-address-form .form-check-input[type='checkbox']:checked + .form-check-label::before {
  background-color: #00C9B8;
  border-color: #00C9B8;
}

.receiver-address-form .custom-control-input[type='checkbox'] + .custom-control-label::before,
.receiver-address-form .form-check-input[type='checkbox'] + .form-check-label::before {
  background-color: transparent;
}

.receiver-address-form {
  text-align: left;
}

.receiver-address-form .custom-control {
  margin-top: 1em;
}

#receiver-address-input {
  margin-top: 10px;
}

.custom-control-input.custom-control-label, .custom-control-label {
  color: inherit!important;
}

#swap-arrow-down {
  margin-bottom: 14px;
  font-size: 2em;
}

.asset-form-submit {
  position: absolute;
  width: fit-content;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 20px auto;
  margin-top: 5px;
}

.custom-button {
  background-color: var(--es-primary-color);
  color: var(--es-white-color);
  border: none;
}

.custom-button:hover {
  background-color: var(--es-hover-color);
  color: var(--es-white-color);
  border: none;
}

.custom-button:active {
  background-color: var(--es-hover-color);
  color: var(--es-white-color);
  border: none;
}

.custom-button:after {
  background-color: var(--es-hover-color);
  color: var(--es-white-color);
  border-color: transparent;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  border: none;
}

.custom-button:focus {
  background-color: var(--es-hover-color);
  color: var(--es-white-color);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.custom-button:focus:after {
  background-color: var(--es-hover-color);
  border-color: transparent;
  box-shadow: none;
  color: var(--es-white-color);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.asset-form-submit button:nth-child(1) {
  margin-right: 10px;
}

.asset-form-submit button {
  width: 200px!important;
}

.title-divider {
  margin-top: 0;
}

.swap-status {
  margin-top: 30%;
  font-size: larger;
}

.loading {
  cursor: default;
}

.loading span {
  font-size: x-large;
  animation: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.loading span:nth-child(2) {
  animation-delay: .2s;
}

.loading span:nth-child(3) {
  animation-delay: .3s;
}

.timeout-notice {
  padding-top: 50px;
  font-size: small;
}

.address-input input[type="text"] {
  font-family: monospace, sans-serif;
}

.address-input ::-webkit-input-placeholder {
  font-family: 'Inter', 'Roboto', 'Fira Sans', sans-serif;
}

.address {
  font-family: monospace, sans-serif;
}

.navbar {
  font-family: var(--es-primary-font-fg), sans-serif;
}

.navbar-brand > #perun-logo {
  margin-bottom: 3px;
  margin-right: 3px;
}

.scrollabletextbox {
  display: inline-block;
  vertical-align: top;
  height:150px;
  width:400px;
  overflow:scroll;
}

#perun-logo-large {
  margin-bottom: 30px;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.modal-body {
  white-space: pre-line;
}

#help-modal {
  max-width: 600px;
}

#privacy-modal {
  max-width: 600px;
}

#privacy-modal .modal-body {
  max-height: 60vh;
  overflow-y: scroll;
}

#contact-dropdown {
  color: white;
  background-color: var(--es-primary-color);
  border: none;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 0.45rem;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(27, 27, 27, .4);
  border-radius: 3px;
}
::-webkit-scrollbar-track{
  background: white;
}

/**
* ----------------------------------------------
* Adapted from react-accessible-accordion/dist/fancy-example.css
* ----------------------------------------------
**/

.accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
}

.accordion__button:hover {
  background-color: #ddd;
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  animation: rotate 0.2s ease-in;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(45deg);
  }
}
